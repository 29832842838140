import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { Fade } from "react-slideshow-image"

import 'react-slideshow-image/dist/styles.css'
import "./_slider.scss"

const fadeProperties = {
  duration: 3000,
  transitionDuration: 1000,
  infinite: true,
  indicators: false,
  arrows: false,
  onChange: (oldIndex, newIndex) => {
    console.log(`fade transition from ${oldIndex} to ${newIndex}`)
  },
}

const SliderKaffeebar = () => {
  const data = useStaticQuery(graphql`{
  slide1: file(relativePath: {eq: "slide_kaffeebar-1.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  slide2: file(relativePath: {eq: "slide_kaffeebar-2.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  slide3: file(relativePath: {eq: "slide_kaffeebar-3.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  slide4: file(relativePath: {eq: "slide_kaffeebar-4.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  slide5: file(relativePath: {eq: "slide_kaffeebar-5.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  slide6: file(relativePath: {eq: "slide_kaffeebar-6.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  slide7: file(relativePath: {eq: "slide_kaffeebar-7.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  slide8: file(relativePath: {eq: "slide_kaffeebar-8.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  slide9: file(relativePath: {eq: "slide_kaffeebar-9.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  slide10: file(relativePath: {eq: "slide_kaffeebar-10.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  slide11: file(relativePath: {eq: "slide_kaffeebar-11.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  slide12: file(relativePath: {eq: "slide_kaffeebar-12.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  slide13: file(relativePath: {eq: "slide_kaffeebar-13.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  slide14: file(relativePath: {eq: "slide_kaffeebar-14.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  slide15: file(relativePath: {eq: "slide_kaffeebar-15.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`)
  return (
    <div className="slide-container">
      <Fade {...fadeProperties}>
        <div className="each-fade">
          <div className="image-container">
            <GatsbyImage
              image={data.slide1.childImageSharp.gatsbyImageData}
              alt="Hildesheim Kaffeebar" />
          </div>
        </div>
        <div className="each-fade">
          <div className="image-container">
          <GatsbyImage
            image={data.slide2.childImageSharp.gatsbyImageData}
            alt="Hildesheim Kaffeeroesterei" />
          </div>
        </div>
        <div className="each-fade">
          <div className="image-container">
          <GatsbyImage
            image={data.slide3.childImageSharp.gatsbyImageData}
            alt="Kaffee Hildesheim" />
          </div>
        </div>        
        <div className="each-fade">
          <div className="image-container">
          <GatsbyImage image={data.slide4.childImageSharp.gatsbyImageData} alt="Kaffee Hannover" />
          </div>
        </div>        
        <div className="each-fade">
          <div className="image-container">
          <GatsbyImage image={data.slide5.childImageSharp.gatsbyImageData} alt="Kaffee Peine" />
          </div>
        </div>        
        <div className="each-fade">
          <div className="image-container">
          <GatsbyImage
            image={data.slide6.childImageSharp.gatsbyImageData}
            alt="Kaffeeroesterei Hannover" />
          </div>
        </div>        
        <div className="each-fade">
          <div className="image-container">
          <GatsbyImage
            image={data.slide7.childImageSharp.gatsbyImageData}
            alt="Kaffeeroesterei Peine" />
          </div>
        </div>        
        <div className="each-fade">
          <div className="image-container">
          <GatsbyImage
            image={data.slide8.childImageSharp.gatsbyImageData}
            alt="Kaffeebar das kleine röstwerk" />
          </div>
        </div>        
        <div className="each-fade">
          <div className="image-container">
          <GatsbyImage image={data.slide9.childImageSharp.gatsbyImageData} alt="Cafe Hildesheim" />
          </div>
        </div>               
        <div className="each-fade">
          <div className="image-container">
          <GatsbyImage
            image={data.slide10.childImageSharp.gatsbyImageData}
            alt="Cafe Hildesheim" />
          </div>
        </div>               
        <div className="each-fade">
          <div className="image-container">
          <GatsbyImage
            image={data.slide11.childImageSharp.gatsbyImageData}
            alt="Cafe Hildesheim" />
          </div>
        </div>               
        <div className="each-fade">
          <div className="image-container">
          <GatsbyImage
            image={data.slide12.childImageSharp.gatsbyImageData}
            alt="Cafe Hildesheim" />
          </div>
        </div>               
        <div className="each-fade">
          <div className="image-container">
          <GatsbyImage
            image={data.slide13.childImageSharp.gatsbyImageData}
            alt="Cafe Hildesheim" />
          </div>
        </div>                                    
        <div className="each-fade">
          <div className="image-container">
          <GatsbyImage
            image={data.slide14.childImageSharp.gatsbyImageData}
            alt="Cafe Hildesheim" />
          </div>
        </div>               
        <div className="each-fade">
          <div className="image-container">
          <GatsbyImage
            image={data.slide15.childImageSharp.gatsbyImageData}
            alt="Cafe Hildesheim" />
          </div>
        </div>               
      </Fade>
    </div>
  );
}

export default SliderKaffeebar
