import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout"
import Seo from "../components/seo"
import SliderKaffeebar from "../components/slider/kaffeebar"
import imgTitleKaffeebar from "../images/page_title_kaffeebar.png"

const Kaffeebar = props => {
  return (
    <Layout>
      <Seo title="kaffebar in hildesheim" />
      <SliderKaffeebar />
      <section className="kaffebar container">
        <img
          className="page-title"
          src={imgTitleKaffeebar}
          alt="unsere kaffeebar in hildesheim"
        />
        <p>
          direkt neben der hildesheimer fußgängerzone und mit blick auf die
          andreaskirche, übrigens mit dem höchsten kirchturm niedersachsens,
          lädt die kaffeebar vom kleinen röstwerk zum erholen ein. in typischer
          röstwerk-atmosphäre können sie hier unsere handgerösteten
          kaffeespezialitäten und espressi genießen. besonders hervorzuheben ist
          auch unsere große auswahl von tee mit vielen außergewöhnlichen sorten
          und unsere eigenen handgeschöpften trinkschokoladen. außerdem: eine
          große auswahl hochwertiger kuchen, kekse, ausgesuchte leckere speisen,
          besondere frühstücke – alles ganz frisch und mit liebe zum detail
          zubereitet. im sommer kann man draußen – mit blick auf die kirche –
          schön in der sonne sitzen oder im liegestuhl relaxen. drinnen sitzt
          man in besonderer atmosphäre und im winter kuschelig am kamin.
        </p>
        <p>wir freuen uns auf sie/euch!</p>
        <p className="content-center">
          <strong>kaffeebar</strong>
          <br />
          andreasplatz 20 · 31134 hildesheim
          <br />
          05121 98910-41
          <br />
          info@daskleineroestwerk.de          
        </p>        
        <p className="content-center">
          <strong>tischreservierung</strong> <br />
          telefon 05121 98910-40
          <br />
          oder direkt bei uns in der kaffeebar
        </p>
        <div className="page-imagebox">
          <div className="page-imagebox-image">
            <GatsbyImage
              image={props.data.page_image1.childImageSharp.gatsbyImageData}
              alt="unser laden im zuckerhut" />
          </div>
          <div className="page-imagebox-image">
            <GatsbyImage
              image={props.data.page_image2.childImageSharp.gatsbyImageData}
              alt="unser laden im zuckerhut" />
          </div>
          <div className="page-imagebox-image">
            <GatsbyImage
              image={props.data.page_image3.childImageSharp.gatsbyImageData}
              alt="unser laden im zuckerhut" />
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`{
  page_image1: file(relativePath: {eq: "page_kaffeebar-1.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  page_image2: file(relativePath: {eq: "page_kaffeebar-2.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  page_image3: file(relativePath: {eq: "page_kaffeebar-3.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`

export default Kaffeebar
